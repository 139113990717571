import { AddIcon, GeneralFormItem, GeneralFormRowOfItems, UsecaseButton } from '@lib/ui-components';
import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { BdoReportType } from '../../../../libs/api-interface/models/report/bdo-report-type.enum';
import { FinancialReportPeriod } from '@lib/api-interface';
import { IssuerEnum } from '../../../../domain/enums/issuer.enum';

export interface CreateReportButtonProps {
  onSaved: () => Promise<any>;
  reportType: BdoReportType;
}

export const CreateBdoReportButton = ({ onSaved, reportType }: CreateReportButtonProps) => {
  const { t } = useTranslation();
  const reportTypeSpecificFilters: (GeneralFormItem | GeneralFormRowOfItems)[] = [];
  const validationSchema = {};

  switch (reportType) {
    case BdoReportType.KPO_REPORT:
      reportTypeSpecificFilters.push(
        {
          rowItems: [
            {
              field: 'projectId',
              label: t('project'),
              type: 'table-select-by-api',
              tableSelectByApiParams: {
                apiPostEndpoint: '/api/project/bdo/withBdo',
                dialogTitle: t('project'),
                displayFormat: '{name}',
                selectedValueField: 'projectId',
                columns: [
                  {
                    label: t('name'),
                    key: 'name',
                    type: 'text',
                  },
                  {
                    label: t('projectNumber'),
                    key: 'projectNumber',
                    type: 'text',
                  },
                ],
              },
            },
            {
              field: 'companyId',
              label: t('company'),
              type: 'table-select-by-api',
              tableSelectByApiParams: {
                apiPostEndpoint: '/api/company/bdo/listByProjectIdForKpo',
                apiPostParams: { projectId: '$projectId' },
                dialogTitle: t('project'),
                displayFormat: '{name}',
                selectedValueField: 'companyId',
                columns: [
                  {
                    label: t('companyName'),
                    key: 'name',
                    type: 'text',
                  },
                ],
              },
            },
          ],
        },
        {
          field: 'reportPeriod',
          label: t('reportPeriod'),
          type: 'select',
          selectOptions: Object.values(FinancialReportPeriod).map((val) => {
            return { label: t(`financialReportPeriod.${val}`), value: val };
          }),
        },
        {
          rowItems: [
            {
              field: 'startYearMonth',
              label: t('from'),
              type: 'date-year-month',
            },
            {
              field: 'endYearMonth',
              label: t('to'),
              type: 'date-year-month',
              isVisible: (data) => data.reportPeriod === FinancialReportPeriod.CUSTOM,
            },
          ],
        },
        {
          rowItems: [
            {
              field: 'onlyPlatformMpds',
              label: t('onlyPlatformMpds'),
              type: 'boolean',
            },
            {
              field: 'includeKeoNumberColumn',
              label: t('includeKeoNumberColumn'),
              type: 'boolean',
            },
          ],
        },
      );
      Object.assign(validationSchema, {
        reportPeriod: Yup.string().required(t('fieldRequired')),
        projectId: Yup.string().required(t('fieldRequired')),
        startYearMonth: Yup.string().required(t('fieldRequired')),
        endYearMonth: Yup.string().when('reportPeriod', {
          is: FinancialReportPeriod.CUSTOM,
          then: Yup.string()
            .required(t('fieldRequired'))
            .test('isAfterStart', t('datesMustBeInSameYear'), function (value) {
              const { startYearMonth } = this.parent;
              return startYearMonth && value ? new Date(startYearMonth).getFullYear() === new Date(value).getFullYear() : true;
            }),
        }),
      });
      break;
    case BdoReportType.KEO_REPORT:
      reportTypeSpecificFilters.push(
        {
          rowItems: [
            {
              field: 'projectId',
              label: t('project'),
              type: 'table-select-by-api',
              tableSelectByApiParams: {
                apiPostEndpoint: '/api/project/bdo/withBdo',
                dialogTitle: t('project'),
                displayFormat: '{name}',
                selectedValueField: 'projectId',
                columns: [
                  {
                    label: t('name'),
                    key: 'name',
                    type: 'text',
                  },
                  {
                    label: t('projectNumber'),
                    key: 'projectNumber',
                    type: 'text',
                  },
                ],
              },
            },
            {
              field: 'companyId',
              label: t('company'),
              type: 'table-select-by-api',
              tableSelectByApiParams: {
                apiPostEndpoint: '/api/company/bdo/listByProjectIdForKeo',
                apiPostParams: { projectId: '$projectId' },
                dialogTitle: t('project'),
                displayFormat: '{name}',
                selectedValueField: 'companyId',
                columns: [
                  {
                    label: t('companyName'),
                    key: 'name',
                    type: 'text',
                  },
                ],
              },
            },
          ],
        },
        {
          field: 'reportPeriod',
          label: t('reportPeriod'),
          type: 'select',
          selectOptions: Object.values(FinancialReportPeriod).map((val) => {
            return { label: t(`financialReportPeriod.${val}`), value: val };
          }),
        },
        {
          rowItems: [
            {
              field: 'startYearMonth',
              label: t('from'),
              type: 'date-year-month',
            },
            {
              field: 'endYearMonth',
              label: t('to'),
              type: 'date-year-month',
              isVisible: (data) => data.reportPeriod === FinancialReportPeriod.CUSTOM,
            },
          ],
        },
      );
      Object.assign(validationSchema, {
        reportPeriod: Yup.string().required(t('fieldRequired')),
        projectId: Yup.string().required(t('fieldRequired')),
        startYearMonth: Yup.string().required(t('fieldRequired')),
        endYearMonth: Yup.string().when('reportPeriod', {
          is: FinancialReportPeriod.CUSTOM,
          then: Yup.string()
            .required(t('fieldRequired'))
            .test('isAfterStart', t('datesMustBeInSameYear'), function (value) {
              const { startYearMonth } = this.parent;
              return startYearMonth && value ? new Date(startYearMonth).getFullYear() === new Date(value).getFullYear() : true;
            }),
        }),
      });
      break;
  }

  return (
    <UsecaseButton
      variant='contained'
      startIcon={<AddIcon />}
      onSaved={onSaved}
      groupName='reports'
      onBeforeSave={(data) => {
        data.issuer = IssuerEnum.ORGANIZATION;
        return data;
      }}
      useCaseName='generate-report'
      buttonLabel={t('generateReport')}
      fields={[...reportTypeSpecificFilters]}
      hiddenValues={{ reportType }}
      validationSchema={validationSchema}
    />
  );
};
