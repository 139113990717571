import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { format } from 'date-fns';

export const collectionRequestDateWarning = (serviceId: string | undefined) => {
  const { t } = useTranslation();

  if (!serviceId) {
    return async () => '';
  }

  const isWithinLastDay = (dateToCheck: Date): boolean => {
    const oneDayInMillis = 24 * 60 * 60 * 1000;
    const dayAgo = new Date(Date.now() - oneDayInMillis);
    return dateToCheck >= dayAgo;
  };
  return async () => {
    return axios.get(`/api/collection-request/last-collection-request-date?serviceId=${serviceId}`).then((response) => {
      const lastCollectionRequestDate = new Date(response.data);
      return lastCollectionRequestDate && isWithinLastDay(lastCollectionRequestDate)
        ? t('lastCollectionRequestDate', { date: format(lastCollectionRequestDate, 'dd.MM.yyyy HH:mm') })
        : '';
    });
  };
};
