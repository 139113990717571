import {
  BdoStatus,
  DynamicCalendarSchedule,
  InvoicingMethod,
  PaymentDay,
  PaymentDue,
  PaymentMethod,
  PaymentPeriod,
  ProjectBranchInfrastructureServiceViewModel,
  ProjectView,
  ProjectWaste,
  ScheduleType,
  ServiceSettings,
  ServiceType,
  SettlementType,
  StaticCalendarSchedule,
} from '@lib/api-interface';
import { BaseModel } from '@lib/common-sdk';
import { Expose } from 'class-transformer';

export enum SettlementUnit {
  MG = 'MG',
  UNIT = 'UNIT',
  M3 = 'M3',
}

export class ServiceViewDetails extends BaseModel {
  @Expose()
  serviceId?: string;
  @Expose()
  customServiceId?: string;
  @Expose()
  projectBranchId?: string;
  @Expose()
  project?: ProjectView;
  @Expose()
  serviceType?: ServiceType;
  @Expose()
  description?: string;
  @Expose()
  acceptanceState?: any;
  @Expose()
  reactionTime?: any;
  @Expose()
  acceptanceDate?: string;
  @Expose()
  activationDate?: string;
  @Expose()
  deactivationDate?: string;
  @Expose()
  activeFromDate?: string;
  @Expose()
  variableAnnotations?: Array<any>;
  @Expose()
  requestEmails?: Array<string>;
  @Expose()
  projectInfrastructureServiceId?: string;
  @Expose()
  projectInfrastructureService?: any;
  @Expose()
  infrastructureOutputMass?: number;
  @Expose()
  infrastructureCount?: number;
  @Expose()
  collectionInfrastructureServiceId?: string;
  @Expose()
  collectionInfrastructureService?: any;
  @Expose()
  collectionInfrastructureOutputMass?: number;
  @Expose()
  collectionInfrastructureCount?: number;
  @Expose()
  projectWaste?: ProjectWaste;
  @Expose()
  utilization?: boolean;
  @Expose()
  subcontractor?: string;
  @Expose()
  subcontractorId?: string;
  @Expose()
  subcontractorCompanyId?: string;
  @Expose()
  transporter?: string;
  @Expose()
  transporterId?: string;
  @Expose()
  generatesKpo?: boolean;
  @Expose()
  bdoStatus?: BdoStatus;
  @Expose()
  licensePlates?: Array<string>;
  @Expose()
  transporterKpo?: string;
  @Expose()
  transporterKpoId?: string;
  @Expose()
  receiverKpo?: string;
  @Expose()
  receiverKpoId?: string;
  @Expose()
  scheduleType?: ScheduleType;
  @Expose()
  staticCalendarSchedule?: StaticCalendarSchedule;
  @Expose()
  dynamicCalendarSchedule?: DynamicCalendarSchedule;
  @Expose()
  onDemand?: boolean;
  @Expose()
  settlementType?: SettlementType;
  @Expose()
  settlementUnit?: SettlementUnit;
  @Expose()
  logisticMinimum?: string;
  @Expose()
  logisticMinimumSurcharge?: string;
  @Expose()
  paymentMethod?: PaymentMethod;
  @Expose()
  separateInvoice?: boolean;
  @Expose()
  invoicingMethod?: InvoicingMethod;
  @Expose()
  constantCost?: boolean;
  @Expose()
  plImport?: boolean;
  @Expose()
  plExport?: boolean;
  @Expose()
  weightReceipt?: boolean;
  @Expose()
  navResourceId?: string;
  @Expose()
  navResourceNumber?: string;
  @Expose()
  navResourceName?: string;
  @Expose()
  settings?: Array<ServiceSettings>;
  @Expose()
  currentSettings?: ServiceSettings;
  @Expose()
  createdBy?: string;
  @Expose()
  createdByEmail?: string;
  @Expose()
  creationDate?: string;
  @Expose()
  lastModifiedBy?: string;
  @Expose()
  lastModifiedByEmail?: string;
  @Expose()
  lastModifiedDate?: string;
  @Expose()
  applicableCollectionInfrastructureService?: ProjectBranchInfrastructureServiceViewModel;
  @Expose()
  applicableCollectionInfrastructureCount?: number;
  @Expose()
  active?: boolean;
  @Expose()
  resourceInBulk?: boolean;
  @Expose()
  resourceInBulkNoPackage?: boolean;
  @Expose()
  apiLinkEmail?: string;
  @Expose()
  clientCollectionNavResourceNumber?: string;
  @Expose()
  clientCollectionNavResourceName?: string;
  @Expose()
  subcontractorCollectionNavResourceNumber?: string;
  @Expose()
  subcontractorCollectionNavResourceName?: string;
  @Expose()
  ownerProjectBranch?: any;
  @Expose()
  declaredPrice?: number;
  @Expose()
  declaredTransports?: number;
  @Expose()
  monthsCount?: number;
  @Expose()
  paymentDay?: PaymentDay;
  @Expose()
  paymentPeriod?: PaymentPeriod;
  @Expose()
  paymentDue?: PaymentDue;
  @Expose()
  bankAccountNumber?: string;
  @Expose()
  bankPaymentReceiver?: string;
  @Expose()
  bankPaymentTitle?: string;
  @Expose()
  lockedForEdit?: boolean;
  @Expose()
  wasteGeneration?: boolean;
  @Expose()
  wasteGenerationTerytPk?: string;
  @Expose()
  wasteGenerationTerytCommune?: string;
  @Expose()
  wasteGenerationAdditionalInfo?: string;
}
