import React from 'react';
import { useTranslation } from 'react-i18next';
import { AggregateCommandButton, Button, DataTable, FormInputTableSelect, Header, HStack, Panel, Snackbar, TextLabel, ToggleButtons, UsecaseButton } from '@lib/ui-components';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  MASS_MIN_VAL,
  MASS_PRECISION,
  PICKUP_MASS_MAX_VAL,
  PlusWorkingDaysRequest,
  PlusWorkingDaysResponse,
  ProjectBranchViewModel,
  ProjectView,
  ServicePriceBearer,
  ServicePriceType,
  ServiceSettings,
  ServiceType,
  ServiceViewDetails,
  VOLUME_MIN_VAL,
  VOLUME_PRECISION,
} from '@lib/api-interface';
import { RestUsecaseClientService } from '@lib/common-sdk';
import { useQuery } from '@tanstack/react-query';
import { addDays } from 'date-fns';
import { useStateIfMounted } from 'use-state-if-mounted';
import { collectionRequestDateWarning } from './collection-request-date-warning';

export const CollectionRequestPickup = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const [activePage, setActivePage] = React.useState(0);
  const [selectedService, setSelectedService] = React.useState<ProjectBranchViewModel | null>(null);
  const [selectedProject, setSelectedProject] = React.useState<ProjectView | null>(null);
  const [tableVisible, setTableVisible] = React.useState(false);
  const [nextWorkingDate, setNextWorkingDate] = React.useState<string>('');
  const { control, setValue, clearErrors } = useForm<any>();
  const currentDay = new Date().getUTCDate();
  const plusWorkingDaysQuery = useQuery(['plusWorkingDays', currentDay, 1, true], () =>
    RestUsecaseClientService.post<PlusWorkingDaysRequest, PlusWorkingDaysResponse>('time', 'plus-working-days', {
      days: 1,
      skipMonday: true,
    }),
  );
  const [isSnackbarOpen, setIsSnackbarOpen] = useStateIfMounted(false);

  React.useEffect(() => {
    setNextWorkingDate(plusWorkingDaysQuery.data?.date ?? '');
  }, [plusWorkingDaysQuery]);

  React.useEffect(() => {
    if (selectedProject != null) {
      setTableVisible(true);
      setTableReloadKey(tableReloadKey + 1);
    } else {
      setTableVisible(false);
    }
  }, [selectedProject]);

  const showServicesHandler = (row: ProjectBranchViewModel) => {
    setActivePage(1);
    setSelectedService(row);
  };

  function handleProjectChange(row: ProjectView) {
    setSelectedProject(row);
  }

  const successMessage = React.useMemo(() => {
    return <Snackbar open={isSnackbarOpen} autoHideDuration={2000} message={t('pickupRequestSuccess')} severity='success' onClose={() => setIsSnackbarOpen(false)} />;
  }, [t, isSnackbarOpen]);

  const panelWithTables = React.useMemo(() => {
    return (
      <Panel>
        {activePage === 0 && (
          <Panel direction='row' gap='3rem' contentMarginTop='1.5rem' contentMarginLeft='1rem'>
            <FormInputTableSelect
              modelName={'projectViews'}
              modelClass={ProjectView}
              label={t('projectNum')}
              dialogTitle={t('projectNumber')}
              displayFormat={'{projectNumber}'}
              name={'project'}
              columns={[
                { key: 'projectNumber', label: t('projectNumber'), type: 'text' },
                { key: 'name', label: t('projectName'), type: 'text' },
              ]}
              control={control}
              setValue={setValue}
              clearErrors={clearErrors}
              onRowSelected={handleProjectChange}
            />
          </Panel>
        )}

        {activePage > 0 && (
          <Panel direction='row' gap='3rem' contentMarginTop='1.5rem' contentMarginLeft='1rem'>
            <TextLabel label={t('projectNum')} value={selectedService?.projectNumber} />
            <TextLabel label={t('client')} value={selectedService?.companyName} />
            <TextLabel label={t('branch')} value={selectedService?.companyBranchName} />
          </Panel>
        )}

        {activePage !== 0 && (
          <HStack align='center'>
            <ToggleButtons labels={[t('backToBranchesList')]} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
          </HStack>
        )}

        {activePage === 0 && tableVisible && (
          <DataTable
            refreshKey={tableReloadKey}
            modelDef={{ modelName: 'projectBranchViews', modelClass: ProjectBranchViewModel }}
            fetchFilters={{
              projectId: selectedProject?.projectId,
              deleted: false,
            }}
            columns={[
              {
                key: 'companyName',
                label: t('companyName'),
                type: 'text',
              },
              {
                key: 'companyBranchName',
                label: t('branch'),
                type: 'text',
              },
              {
                key: 'registrationAddress.town',
                label: t('locality'),
                type: 'text',
              },
              {
                key: 'registrationAddress.street',
                label: t('street'),
                type: 'text',
              },
              {
                key: 'registrationAddress.houseNum',
                label: t('houseNum'),
                type: 'text',
              },
              {
                key: 'registrationAddress.province',
                label: t('province'),
                type: 'text',
              },
              {
                key: 'activeBdo',
                label: t('activeBdo'),
                type: 'boolean',
                align: 'center',
              },
            ]}
            rowOptions={[
              {
                renderer: (row: ProjectBranchViewModel) => <Button label={t('showServices')} color='primary' variant='text' onClick={async () => showServicesHandler(row)} />,
              },
            ]}
          />
        )}

        {activePage === 1 && (
          <>
            <Header label={t('servicesList')} />
            <DataTable
              refreshKey={tableReloadKey}
              fetchUrl={'/api/service'}
              fetchFilters={{
                projectId: selectedService?.projectId,
                companyBranchId: selectedService?.branchId,
                serviceType: 'PER_REQUEST',
                acceptanceState: 'ACCEPTED',
                active: true,
              }}
              columns={[
                {
                  key: 'projectWaste.wasteCode',
                  label: t('wasteCode'),
                  type: 'text',
                },
                {
                  key: 'projectWaste.wasteDescription',
                  label: t('wasteName'),
                  type: 'text',
                },
                {
                  key: 'projectWaste.projectWasteDescription',
                  label: t('ownDescriptionOfWaste'),
                  type: 'text',
                },
                {
                  key: 'applicableCollectionInfrastructureCount',
                  label: t('receiptSizeUnit'),
                  type: 'numeric',
                },
                {
                  key: 'applicableCollectionInfrastructureService.name',
                  label: t('collectionMethod'),
                  type: 'text',
                },
                {
                  key: 'currentSettings.clientCollectionPrice.stringValue',
                  label: t('clientPrice'),
                  type: 'text',
                  colorOnCondition: (row) => (row.currentSettings.clientCollectionPrice?.bearer === ServicePriceBearer.ORGANIZATION_COST ? '#EB5757' : '#0AAD50'),
                },
                {
                  key: 'currentSettings.subcontractorCollectionPrice.stringValue',
                  label: t('subcontractorPrice'),
                  type: 'text',
                  colorOnCondition: (row) => (row.currentSettings.subcontractorCollectionPrice?.bearer === ServicePriceBearer.ORGANIZATION_COST ? '#EB5757' : '#0AAD50'),
                },
                {
                  key: 'subcontractor',
                  label: t('subcontractor'),
                  type: 'text',
                },
                {
                  key: 'transporter',
                  label: t('transportEntity'),
                  type: 'text',
                },
                {
                  key: 'transporterKpo',
                  label: t('transporterKPO'),
                  type: 'text',
                },
                {
                  key: 'receiverKpo',
                  label: t('receiverKPO'),
                  type: 'text',
                },
              ]}
              rowOptions={[
                {
                  renderer: (row) => (
                    <CollectionRequestDialog
                      row={row}
                      nextWorkingDate={nextWorkingDate}
                      onSubmit={() => {
                        setTableReloadKey(tableReloadKey + 1);
                        setIsSnackbarOpen(true);
                      }}
                    />
                  ),
                },
              ]}
            />
            <Header label={t('pickupsInProgress')} />
            <DataTable
              refreshKey={tableReloadKey}
              modelDef={{ modelName: 'collectionRequestViews' }}
              fetchPostUrl='/api/collection-request/fetch-in-realization-collection-requests-for-branch'
              fetchFilters={{
                projectId: selectedService?.projectId,
                companyBranchId: selectedService?.branchId,
              }}
              columns={[
                {
                  key: 'collectionDate',
                  label: t('pickupDate'),
                  type: 'date',
                  dateFormat: 'd.M.yyyy',
                },
                {
                  key: 'creationDate',
                  label: t('creationDate'),
                  type: 'date',
                  dateFormat: 'd.M.yyyy / HH:mm',
                },
                {
                  key: 'wasteCode',
                  label: t('wasteCode'),
                  type: 'text',
                },
                {
                  key: 'wasteName',
                  label: t('wasteName'),
                  type: 'text',
                },
                {
                  key: 'wasteDescription',
                  label: t('ownDescriptionOfWaste'),
                  type: 'text',
                },
                {
                  key: 'containers',
                  label: t('receiptSizeUnit'),
                  type: 'numeric',
                },
                {
                  key: 'mass',
                  label: t('massMg'),
                  type: 'numeric',
                },
                {
                  key: 'container',
                  label: t('collectionMethod'),
                  type: 'text',
                },
                {
                  key: 'clientCollectionPrice.stringValue',
                  label: t('clientPrice'),
                  type: 'text',
                  colorOnCondition: (row) => (row.clientCollectionPrice?.bearer === ServicePriceBearer.ORGANIZATION_COST ? '#EB5757' : '#0AAD50'),
                },
                {
                  key: 'subcontractorCollectionPrice.stringValue',
                  label: t('subcontractorPrice'),
                  type: 'text',
                  colorOnCondition: (row) => (row.subcontractorCollectionPrice?.bearer === ServicePriceBearer.ORGANIZATION_COST ? '#EB5757' : '#0AAD50'),
                },
                {
                  key: 'subcontractor',
                  label: t('subcontractor'),
                  type: 'text',
                },
                {
                  key: 'transporter',
                  label: t('transportEntity'),
                  type: 'text',
                },
                {
                  key: 'transporterKpo',
                  label: t('transporterKPO'),
                  type: 'text',
                },
                {
                  key: 'receiverKpo',
                  label: t('receiverKPO'),
                  type: 'text',
                },
                {
                  key: 'comment',
                  label: t('comment'),
                  type: 'text',
                },
              ]}
              rowOptions={[
                {
                  renderer: (row) => (
                    <AggregateCommandButton
                      aggregateName={'CollectionRequestAggregate'}
                      aggregateId={row.collectionRequestId}
                      commandName={'complete'}
                      buttonLabel={t('done')}
                      color='secondary'
                      variant='contained'
                      forceConfirmation={true}
                      onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                    />
                  ),
                },
                {
                  renderer: (row) => (
                    <AggregateCommandButton
                      aggregateName={'CollectionRequestAggregate'}
                      aggregateId={row.collectionRequestId}
                      commandName={'comment'}
                      buttonLabel={t('comment')}
                      dialogTitle={t('edit')}
                      variant='outlined'
                      fields={[
                        {
                          field: 'comment',
                          label: t('comment'),
                          type: 'text',
                          defaultValue: row.comment,
                        },
                      ]}
                      onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                    />
                  ),
                },
              ]}
            />
          </>
        )}
      </Panel>
    );
  }, [t, activePage, tableReloadKey, selectedProject, selectedService, nextWorkingDate]);

  return (
    <>
      {successMessage}
      {panelWithTables}
    </>
  );
};

interface CollectionRequestDialogProps {
  row: ServiceViewDetails;
  onSubmit: () => void;
  nextWorkingDate: string;
}

const CollectionRequestDialog = (props: CollectionRequestDialogProps) => {
  const { t } = useTranslation();
  const row = props.row;
  const hasPriceType = (date: string, priceType: ServicePriceType): boolean => {
    const setting = row.settings
      ?.sort((s1, s2) => {
        if (s1.activeFromDate === s2.activeFromDate) return 0;
        return s1.activeFromDate! < s2.activeFromDate! ? 1 : -1;
      })
      ?.find((setting: ServiceSettings) => setting.activeFromDate! <= date);
    return setting?.clientCollectionPrice?.priceType === priceType;
  };

  return (
    <>
      <UsecaseButton
        key={row.id}
        groupName='collection-request'
        useCaseName='create-collection-request'
        buttonLabel={t('reportPickup')}
        dialogTitle={t('sendPickupRequest')}
        isDisabled={!row.active || (row.serviceType === ServiceType.ADDITIONAL && row.constantCost)}
        onDialogOpenWarning={collectionRequestDateWarning(row.serviceId)}
        fields={[
          {
            label: t('pickupDate'),
            type: 'date',
            field: 'collectionDate',
            defaultValue: props.nextWorkingDate,
            maxDate: addDays(new Date(), 30),
            minDate: new Date(2020, 0, 1),
          },
          {
            label: t('bdoTonnesOfwaste'),
            field: 'mass',
            type: 'numeric',
            numericPrecision: MASS_PRECISION,
            numericMinValue: MASS_MIN_VAL,
            numericMaxValue: PICKUP_MASS_MAX_VAL,
          },
          {
            label: t('noContainersForPickup'),
            type: 'numeric',
            field: 'containers',
            numericMinValue: 1,
            defaultValue: 1,
          },
          {
            label: t('volume'),
            type: 'numeric',
            field: 'volume',
            defaultValue: row.applicableCollectionInfrastructureService === null ? 1 : undefined,
            numericPrecision: VOLUME_PRECISION,
            numericMinValue: VOLUME_MIN_VAL,
            numericMaxValue: row.applicableCollectionInfrastructureService?.volume,
            isVisible: (data: any) => hasPriceType(data.collectionDate, ServicePriceType.M3),
          },
          {
            label: t('comment'),
            type: 'text',
            field: 'comment',
          },
        ]}
        validationSchema={{
          collectionDate: Yup.string().required(t('fieldRequired')),
          mass: Yup.string()
            .required(t('fieldRequired'))
            .matches(/^\d*\.?,?\d*$/, { message: t('bdoTonnesOfwaste.matches') })
            .test('massGreaterThanZero', t('bdoTonnesOfwaste.matches'), function (value) {
              return parseFloat(value!) > 0;
            }),
          containers: Yup.string().when('collectionDate', {
            is: (collectionDate: string) => hasPriceType(collectionDate, ServicePriceType.UNIT),
            then: Yup.string()
              .required(t('fieldRequired'))
              .matches(/^\d+$/, { message: t('noContainersForPickup.matches') })
              .test('containersLessThanApplicableContainersCount', t('noContainersForPickup.exceeded'), function (value) {
                return parseInt(value!) <= (row.applicableCollectionInfrastructureCount ?? 0);
              }),
          }),
          volume: Yup.string().when('collectionDate', {
            is: (collectionDate: string) => hasPriceType(collectionDate, ServicePriceType.M3),
            then: Yup.string()
              .required(t('fieldRequired'))
              .matches(/^\d*\.?,?\d*$/, { message: t('volumeM3.matches') }),
          }),
        }}
        hiddenValues={{
          serviceId: row.serviceId,
          type: 'PICKUP',
        }}
        onSaved={async () => props.onSubmit()}
      />
    </>
  );
};
