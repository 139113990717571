import { useTranslation } from 'react-i18next';
import {
  MASS_MIN_VAL,
  MASS_PRECISION,
  PICKUP_MASS_MAX_VAL,
  PlusWorkingDaysRequest,
  PlusWorkingDaysResponse,
  ServicePriceType,
  ServiceSettings,
  ServiceType,
  ServiceViewDetails,
  VOLUME_MIN_VAL,
  VOLUME_PRECISION,
} from '@lib/api-interface';
import { UsecaseButton } from '@lib/ui-components';
import * as Yup from 'yup';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { RestUsecaseClientService } from '@lib/common-sdk';
import { collectionRequestDateWarning } from '../../organization/collection-requests/collection-request-date-warning';

interface ClientCollectionRequestPickupCommonServiceDialogProps {
  row: ServiceViewDetails;
  reload: () => void;
}

export const CommonServicePickupDialog = (props: ClientCollectionRequestPickupCommonServiceDialogProps) => {
  const { t } = useTranslation();
  const row = props.row;
  const [nextWorkingDate, setNextWorkingDate] = React.useState<string>('');
  const currentDay = new Date().getUTCDate();
  const plusWorkingDaysQuery = useQuery(['plusWorkingDays', currentDay, 1, true], () =>
    RestUsecaseClientService.post<PlusWorkingDaysRequest, PlusWorkingDaysResponse>('time', 'plus-working-days', {
      days: 1,
      skipMonday: true,
    }),
  );

  React.useEffect(() => {
    setNextWorkingDate(plusWorkingDaysQuery.data?.date || '');
  }, [plusWorkingDaysQuery]);

  const hasPriceType = (date: string, priceType: ServicePriceType): boolean => {
    const setting = row.settings
      ?.sort((s1, s2) => {
        if (s1.activeFromDate === s2.activeFromDate) return 0;
        return s1.activeFromDate! < s2.activeFromDate! ? 1 : -1;
      })
      ?.find((setting: ServiceSettings) => setting.activeFromDate! <= date);
    return setting?.clientCollectionPrice?.priceType === priceType;
  };

  return (
    <UsecaseButton
      key={row.id}
      groupName='collection-request-client'
      useCaseName='createCollectionRequestByClient'
      buttonLabel={t('reportPickup')}
      color={'secondary'}
      variant={'contained'}
      dialogTitle={t('sendPickupRequest')}
      isDisabled={!row.active || (row.serviceType === ServiceType.ADDITIONAL && row.constantCost)}
      onDialogOpenWarning={collectionRequestDateWarning(row.serviceId)}
      fields={[
        {
          label: t('bdoTonnesOfwaste'),
          field: 'mass',
          type: 'numeric',
          numericPrecision: MASS_PRECISION,
          numericMinValue: MASS_MIN_VAL,
          numericMaxValue: PICKUP_MASS_MAX_VAL,
        },
        {
          label: t('noContainersForPickup'),
          type: 'numeric',
          field: 'containers',
          numericMinValue: 1,
          numericMaxValue: row?.applicableCollectionInfrastructureCount || 1,
          defaultValue: row.applicableCollectionInfrastructureService === null ? 1 : undefined,
        },
        {
          label: t('volume'),
          type: 'numeric',
          field: 'volume',
          defaultValue: row.applicableCollectionInfrastructureService === null ? 1 : undefined,
          numericPrecision: VOLUME_PRECISION,
          numericMinValue: VOLUME_MIN_VAL,
          numericMaxValue: row.applicableCollectionInfrastructureService?.volume,
          isVisible: (data: any) => hasPriceType(data.collectionDate, ServicePriceType.M3),
        },
        {
          label: t('comment'),
          type: 'text',
          field: 'comment',
        },
      ]}
      validationSchema={{
        mass: Yup.string()
          .required(t('fieldRequired'))
          .matches(/^\d*\.?,?\d*$/, { message: t('bdoTonnesOfwaste.matches') })
          .test('massGreaterThanZero', t('bdoTonnesOfwaste.matches'), function (value) {
            return parseFloat(value!) > 0;
          }),
        containers: Yup.string().when('collectionDate', {
          is: (collectionDate: string) => hasPriceType(collectionDate, ServicePriceType.UNIT),
          then: Yup.string()
            .required(t('fieldRequired'))
            .matches(/^\d+$/, { message: t('noContainersForPickup.matches') })
            .test('containersLessThanApplicableContainersCount', t('noContainersForPickup.exceeded'), function (value) {
              return parseInt(value!) <= (row.applicableCollectionInfrastructureCount || 0);
            }),
        }),
        volume: Yup.string().when('collectionDate', {
          is: (collectionDate: string) => hasPriceType(collectionDate, ServicePriceType.M3),
          then: Yup.string()
            .required(t('fieldRequired'))
            .matches(/^\d*\.?,?\d*$/, { message: t('volumeM3.matches') }),
        }),
      }}
      hiddenValues={{
        collectionDate: nextWorkingDate,
        serviceId: row.serviceId,
        type: 'PICKUP',
      }}
      // backInsteadOfCancelButton={true}
      onSaved={async () => props.reload()}
    />
  );
};
