import React, { useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import LoginWrapper from './login-wrapper';
import logo from './logo.svg';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Background from './background';

export const PublicLayout = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const privacyPolicyLink = currentLanguage === 'pl' ? 'https://interzero.pl/polityka-prywatnosci/' : 'https://interzero.pl/en/privacy-policy/';
  const poiRegulation = '/Regulamin POI.pdf';
  const cookiePolicyLink = currentLanguage === 'pl' ? 'https://interzero.pl/cookie-policy-eu/' : 'https://interzero.pl/en/cookie-policy-eu/';

  const linkStyle = {
    color: 'white',
    textShadow: '-1px -1px 0 #002652, 1px -1px 0 #002652, -1px 1px 0 #002652, 1px 1px 0 #002652',
    marginRight: '24px',
    whiteSpace: 'nowrap' as const,
  };

  const ResponsiveTypography = styled(Typography)({
    '@media (max-width:700px)': {
      fontSize: '0.8rem',
      textShadow: 'none',
      color: '#002652',
      textDecoration: 'underline',
      textDecorationColor: '#002652',
    },
  });

  const ResponsiveBox = styled(Box)({
    position: 'absolute',
    left: 0,
    bottom: 0,
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width:700px)': {
      position: 'fixed',
      width: '100%',
      justifyContent: 'center',
      backgroundColor: '#eee',
      padding: '8px',
    },
  });

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';
    return () => {
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Logowanie - Platforma Odpadowa Interzero</title>
      </Helmet>
      <Grid container height='100vh' bgcolor='#eee' direction='column' justifyContent='center' alignItems='center' style={{ overflow: 'hidden' }}>
        <LoginWrapper>
          <Background />
          <Box sx={{ position: 'absolute', right: 0, top: 0, width: '15%', padding: '25px', minWidth: '200px' }}>
            <img src={logo} alt='Logo Interzero' />
          </Box>
          <Outlet />
          <ResponsiveBox
            sx={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              padding: '16px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Link to={privacyPolicyLink} style={linkStyle} target={'_blank'}>
              <ResponsiveTypography variant='h6'>Polityka prywatności</ResponsiveTypography>
            </Link>
            <Link to={poiRegulation} style={linkStyle} target={'_blank'}>
              <ResponsiveTypography variant='h6'>Regulamin POI</ResponsiveTypography>
            </Link>
            <Link to={cookiePolicyLink} style={linkStyle} target={'_blank'}>
              <ResponsiveTypography variant='h6'>Polityka cookies</ResponsiveTypography>
            </Link>
          </ResponsiveBox>
        </LoginWrapper>
      </Grid>
    </>
  );
};
