import { ServiceButtonMode, ServiceButtonProps } from '../service-button-props';
import { useTranslation } from 'react-i18next';
import { useProjectBranchContext, useProjectBranchSettingsContext, useProjectBranchTermsContext } from '@context';
import { UsecaseButton } from '@lib/ui-components';
import {
  bdoStatusControls,
  getServiceButtonConsts,
  infrastructureServiceFields,
  invoicingMethodControls,
  paymentMethodControls,
  priceFields,
  scheduleTypeControls,
  serviceValidationSchema,
  settlementTypeControls,
} from './shared';
import React from 'react';
import { MASS_PRECISION, ServiceType, SettlementType } from '@lib/api-interface';

export type SharedServiceButtonProps = ServiceButtonProps & { type: ServiceType.SCHEDULED | ServiceType.PER_REQUEST };

export const ServiceButton = (props: SharedServiceButtonProps) => {
  const { t } = useTranslation();
  const projectBranch = useProjectBranchContext();
  const projectBranchTerms = useProjectBranchTermsContext();
  const { label, useCaseName, startIcon } = getServiceButtonConsts(t, props.serviceButtonMode);
  const projectBranchSettings = useProjectBranchSettingsContext();

  function isResourceInBulk(data: any): boolean {
    return data.resourceInBulk === true;
  }

  function isNoPackage(data: any): boolean {
    return data.resourceInBulkNoPackage === true;
  }

  const initialService = props.serviceButtonMode !== ServiceButtonMode.CREATE ? props?.service : undefined;
  const hiddenValues =
    props.serviceButtonMode === ServiceButtonMode.EDIT
      ? {
          serviceId: initialService!.serviceId,
        }
      : {
          projectBranchId: projectBranch.projectBranchId,
          companyBranchId: projectBranch.branchId,
          projectId: projectBranch.projectId,
          acceptanceState: 'INITIALIZED',
          serviceType: props.type,
        };

  const prices =
    props.serviceButtonMode !== ServiceButtonMode.EDIT
      ? [
          ...priceFields('clientTransportPrice', t, t('clientTransport'), {
            initialService: initialService,
            defaultPrice: undefined,
            defaultType: initialService?.currentSettings?.clientTransportPrice?.priceType ?? 'UNIT',
            defaultBearer: initialService?.currentSettings?.clientTransportPrice?.bearer ?? 'ORGANIZATION_REVENUE',
            fixedPriceType: (data) => !data.pix,
            fixedBearer: true,
            overrideTypeSelectOptions: [
              { label: t('priceType.UNIT'), value: 'UNIT' },
              { label: t('priceType.MG'), value: 'MG' },
            ],
            type: initialService?.serviceType || props.type,
          }),
          ...priceFields('subcontractorTransportPrice', t, t('subcontractorTransport'), {
            initialService: initialService,
            defaultPrice: undefined,
            defaultType: initialService?.currentSettings?.subcontractorTransportPrice?.priceType ?? 'UNIT',
            defaultBearer: initialService?.currentSettings?.subcontractorTransportPrice?.bearer ?? 'ORGANIZATION_COST',
            fixedPriceType: (data) => !data.pix,
            fixedBearer: true,
            overrideTypeSelectOptions: [
              { label: t('priceType.UNIT'), value: 'UNIT' },
              { label: t('priceType.MG'), value: 'MG' },
            ],
            type: initialService?.serviceType || props.type,
          }),
          ...priceFields('clientCollectionPrice', t, t('clientCollection'), {
            initialService: initialService,
            defaultPrice: undefined,
            defaultType: initialService?.currentSettings?.clientCollectionPrice?.priceType ?? 'MG',
            defaultBearer: initialService?.currentSettings?.clientCollectionPrice?.bearer ?? 'ORGANIZATION_REVENUE',
            navResourceVisible: () => true,
            isVisible: (data) => !data.pix,
            type: initialService?.serviceType || props.type,
          }),
          ...priceFields('subcontractorCollectionPrice', t, t('subcontractorCollection'), {
            initialService: initialService,
            defaultPrice: undefined,
            defaultType: initialService?.currentSettings?.subcontractorCollectionPrice?.priceType ?? 'MG',
            defaultBearer: initialService?.currentSettings?.subcontractorCollectionPrice?.bearer ?? 'ORGANIZATION_COST',
            navResourceVisible: () => true,
            type: initialService?.serviceType || props.type,
          }),
        ]
      : [];

  return (
    <UsecaseButton
      dataTestId={`service-button-${props.serviceButtonMode}`}
      maxWidth='lg'
      buttonLabel={label}
      dialogTitle={label}
      startIcon={startIcon}
      groupName='service'
      useCaseName={useCaseName}
      fields={[
        {
          rowItems: [
            {
              label: t('wasteCode'),
              type: 'table-select',
              field: 'projectWasteId',
              defaultValue: initialService?.projectWaste?.projectWasteId,
              tableSelectParams: {
                selectedValueField: 'projectWasteId',
                modelName: 'projectWasteViews',
                dialogTitle: t('wasteCode'),
                displayFormat: '{wasteCode} - {wasteDescription}',
                fetchFilters: {
                  projectId: projectBranch.projectId,
                },
                columns: [
                  {
                    key: 'wasteCode',
                    label: t('wasteCode'),
                    type: 'text',
                  },
                  {
                    key: 'wasteDescription',
                    label: t('wasteDescription'),
                    type: 'text',
                  },
                  {
                    key: 'projectWasteDescription',
                    label: t('ownDescriptionOfWaste'),
                    type: 'text',
                  },
                ],
              },
            },
            {
              label: t('pix'),
              type: 'boolean',
              field: 'pix',
              defaultValue: initialService?.currentSettings?.pix,
            },
            {
              label: t('Model.Infrastructure.InfrastructureCategory.IN_BULK'),
              type: 'boolean',
              field: 'resourceInBulk',
              defaultValue: initialService?.resourceInBulk,
            },
            {
              label: t('noPackage'),
              type: 'boolean',
              field: 'resourceInBulkNoPackage',
              defaultValue: initialService?.resourceInBulk === true && initialService?.collectionInfrastructureServiceId == null,
              isVisible: (data) => isResourceInBulk(data) && [ServiceType.PER_REQUEST, ServiceType.SCHEDULED].includes(props.type),
            },
          ],
        },
        {
          rowItems: infrastructureServiceFields(t, {
            service: initialService,
            projectBranchId: projectBranch.projectBranchId!,
            resource: true,
            label: t('resourceName'),
            idField: 'projectInfrastructureServiceId',
            countField: 'infrastructureCount',
            hideCountField: true,
            massField: 'infrastructureOutputMass',
            isCommune: false,
            isVisible: (data) => !isResourceInBulk(data),
          }),
        },
        {
          rowItems: infrastructureServiceFields(t, {
            service: initialService,
            projectBranchId: projectBranch.projectBranchId!,
            collectionMethod: true,
            label: t('collectionMethod'),
            idField: 'collectionInfrastructureServiceId',
            countField: 'collectionInfrastructureCount',
            massField: 'collectionInfrastructureOutputMass',
            isCommune: false,
            isVisible: (data) => !isNoPackage(data),
          }),
        },
        {
          rowItems: [
            {
              label: t('massMg'),
              type: 'numeric',
              field: 'noPackageMass',
              defaultValue: initialService?.collectionInfrastructureOutputMass,
              numericPrecision: MASS_PRECISION,
              isVisible: (data) => isNoPackage(data),
            },
          ],
        },
        {
          rowItems: [
            {
              label: t('activationDate'),
              type: 'date',
              field: 'activationDate',
              defaultValue: props.serviceButtonMode === ServiceButtonMode.EDIT ? initialService?.activationDate : undefined,
            },
            {
              label: t('deactivationDate'),
              type: 'date',
              field: 'deactivationDate',
              defaultValue: props.serviceButtonMode === ServiceButtonMode.EDIT ? initialService?.deactivationDate : undefined,
            },
          ],
        },
        {
          rowItems: [
            {
              label: t('forUtilization'),
              type: 'boolean',
              field: 'utilization',
              defaultValue: initialService?.utilization,
            },
            {
              label: t('constantCost'),
              type: 'boolean',
              field: 'constantCost',
              defaultValue: initialService?.constantCost,
              isVisible: () => props.type === ServiceType.SCHEDULED,
            },
            {
              label: t('organizationAsSide'),
              type: 'boolean',
              field: 'organizationAsSide',
              defaultValue:
                initialService?.currentSettings?.organizationAsSide === undefined
                  ? projectBranchSettings?.organizationIsParticipating
                  : initialService?.currentSettings?.organizationAsSide,
              isVisible: () => props.serviceButtonMode !== ServiceButtonMode.EDIT,
            },
            {
              label: t('plImport'),
              type: 'boolean',
              field: 'plImport',
              defaultValue: initialService?.plImport,
            },
            {
              label: t('plExport'),
              type: 'boolean',
              field: 'plExport',
              defaultValue: initialService?.plExport,
            },
            {
              label: t('weightReceipt'),
              type: 'boolean',
              field: 'weightReceipt',
              defaultValue: initialService?.weightReceipt,
            },
          ],
        },
        ...prices,
        {
          rowItems: [
            {
              label: t('settledSubcontractorName'),
              type: 'table-select',
              field: 'subcontractorId',
              defaultValue: initialService?.subcontractorId,
              margin: '0 0 1rem 0',
              tableSelectParams: {
                modelName: 'companyBranchViews',
                search: 'forSettledSubcontractor',
                dialogTitle: t('subcontractor'),
                displayFormat: '{name}',
                selectedValueField: 'branchId',
                columns: [
                  {
                    label: t('name'),
                    key: 'name',
                    type: 'text',
                  },
                  {
                    label: t('town'),
                    key: 'registrationAddress.town',
                    type: 'text',
                  },
                  {
                    label: t('nip'),
                    key: 'nip',
                    type: 'text',
                  },
                  {
                    label: t('bdoIdentificationNumber'),
                    key: 'bdoIdentificationNumber',
                    type: 'text',
                  },
                ],
              },
            },
            {
              label: t('reactionTime'),
              type: 'numeric',
              field: 'reactionTime',
              defaultValue: initialService?.reactionTime || (props.type === ServiceType.PER_REQUEST ? 1 : undefined),
              width: '10rem',
              isVisible: () => props.type === ServiceType.PER_REQUEST,
            },
            {
              label: t('generatesKpo'),
              type: 'boolean',
              field: 'generatesKpo',
              defaultValue: props.serviceButtonMode === ServiceButtonMode.CREATE ? true : initialService?.generatesKpo,
              width: '20rem',
              margin: '0 0 0 1rem',
            },
          ],
        },
        {
          label: t('settledTransportEntity'),
          type: 'table-select',
          field: 'transporterId',
          defaultValue: initialService?.transporterId,
          tableSelectParams: {
            modelName: 'companyBranchViews',
            search: 'forSettledTransporter',
            dialogTitle: t('transportEntity'),
            displayFormat: '{name}',
            selectedValueField: 'branchId',
            columns: [
              {
                label: t('name'),
                key: 'name',
                type: 'text',
              },
              {
                label: t('town'),
                key: 'registrationAddress.town',
                type: 'text',
              },
              {
                label: t('nip'),
                key: 'nip',
                type: 'text',
              },
              {
                label: t('bdoIdentificationNumber'),
                key: 'bdoIdentificationNumber',
                type: 'text',
              },
            ],
          },
        },
        {
          label: t('transporterKPO'),
          type: 'table-select',
          field: 'transporterKpoId',
          defaultValue: initialService?.transporterKpoId,
          tableSelectParams: {
            modelName: 'companyBranchViews',
            search: 'forKpoTransporter',
            dialogTitle: t('transporterKPO'),
            displayFormat: '{name}',
            selectedValueField: 'branchId',
            columns: [
              {
                label: t('name'),
                key: 'name',
                type: 'text',
              },
              {
                label: t('town'),
                key: 'registrationAddress.town',
                type: 'text',
              },
              {
                label: t('nip'),
                key: 'nip',
                type: 'text',
              },
              {
                label: t('bdoIdentificationNumber'),
                key: 'bdoIdentificationNumber',
                type: 'text',
              },
            ],
          },
        },
        {
          label: t('receiverKPO'),
          type: 'table-select',
          margin: '0 0 1rem 0',
          field: 'receiverKpoId',
          defaultValue: initialService?.receiverKpoId,
          tableSelectParams: {
            modelName: 'companyBranchViews',
            search: 'forKpoReceiver',
            dialogTitle: t('receiverKPO'),
            displayFormat: '{name}',
            selectedValueField: 'branchId',
            columns: [
              {
                label: t('name'),
                key: 'name',
                type: 'text',
              },
              {
                label: t('town'),
                key: 'registrationAddress.town',
                type: 'text',
              },
              {
                label: t('nip'),
                key: 'nip',
                type: 'text',
              },
              {
                label: t('bdoIdentificationNumber'),
                key: 'bdoIdentificationNumber',
                type: 'text',
              },
            ],
          },
        },
        {
          rowItems: [
            bdoStatusControls(t, [ServiceType.PER_REQUEST, ServiceType.SCHEDULED].includes(props.type), initialService?.bdoStatus || 'CARD_ACCEPTED'),
            {
              label: t('licensePlates'),
              type: 'text',
              field: 'licensePlates',
              defaultValue: initialService?.licensePlates,
            },
          ],
        },
        {
          label: t('apiLinkEmail'),
          type: 'text',
          field: 'apiLinkEmail',
          defaultValue: initialService?.apiLinkEmail,
          isVisible: (data) => data.bdoStatus === 'LINK_TO_SUBCONTRACTOR_API' || data.bdoStatus === 'LINK_TO_SUBCONTRACTOR',
        },
        {
          label: t('wasteGeneration'),
          type: 'boolean',
          field: 'wasteGeneration',
          defaultValue: initialService?.wasteGeneration,
        },
        {
          rowItems: [
            {
              label: t('wasteGenerationLocation'),
              type: 'table-select-by-api',
              field: 'wasteGenerationTerytPk',
              tableSelectByApiParams: {
                apiPostEndpoint: '/api/organization/bdo/search/teryt-commune',
                dialogTitle: t('bdoIdentificationNumber'),
                displayFormat: '{fulltext}',
                selectedValueField: 'pk',
                initialEntity: {
                  wasteGenerationTerytPk: initialService?.wasteGenerationTerytPk ?? '',
                  fulltext: initialService?.wasteGenerationTerytCommune ?? '',
                },
                columns: [
                  { label: t('name'), key: 'fulltext', type: 'text' },
                  { label: t('province'), key: 'voivodeship', type: 'text' },
                  { label: t('district'), key: 'county', type: 'text' },
                  { label: t('commune'), key: 'commune', type: 'text' },
                  { label: t('communeType'), key: 'communeType', type: 'text' },
                ],
                otherValuesMap: {
                  fulltext: 'wasteGenerationTerytCommune',
                },
                queryFields: [{ label: t('bdoSearch'), type: 'text', field: 'query' }],
              },
              isVisible: (data) => data.wasteGeneration,
            },
            {
              label: t('wasteGenerationAdditionalInfo'),
              type: 'text',
              field: 'wasteGenerationAdditionalInfo',
              defaultValue: initialService?.wasteGenerationAdditionalInfo,
              isVisible: (data) => data.wasteGeneration,
            },
            {
              label: '',
              type: 'text',
              field: 'wasteGenerationTerytCommune',
              defaultValue: initialService?.wasteGenerationTerytCommune,
              isVisible: () => false,
            },
          ],
        },
        ...(props.type === ServiceType.SCHEDULED ? scheduleTypeControls(t, initialService) : []),
        {
          rowItems: [
            settlementTypeControls(
              t,
              props.serviceButtonMode === ServiceButtonMode.CREATE
                ? projectBranchTerms.aggregatingSettlement
                  ? SettlementType.PER_MONTH
                  : SettlementType.PER_COLLECTION
                : initialService?.settlementType,
            ),
            {
              label: t('logisticMinimumSurcharge'),
              type: 'text',
              field: 'logisticMinimumSurcharge',
              defaultValue: initialService?.logisticMinimumSurcharge,
            },
          ],
        },
        {
          rowItems: [
            paymentMethodControls(t, initialService?.paymentMethod || projectBranchTerms.paymentMethod),
            {
              label: t('separatedInvoice'),
              type: 'boolean',
              field: 'separateInvoice',
              defaultValue: initialService?.separateInvoice,
            },
            invoicingMethodControls(t, initialService?.invoicingMethod || projectBranchTerms.invoicingMethod),
          ],
        },
        {
          label: '',
          type: 'header',
          field: '',
        },
      ]}
      hiddenValues={hiddenValues}
      onBeforeSave={(formData) => {
        delete formData.selectedInfrastructureCategory;
        delete formData.selectedProjectInfrastructureId;
        if (formData.resourceInBulk === true) {
          delete formData.projectInfrastructureService;
          delete formData.infrastructureCount;
          delete formData.infrastructureBallerOutputMass;
        }
        if (formData.onDemand) {
          delete formData.scheduleType;
          delete formData.staticCalendarSchedule;
          delete formData.dynamicCalendarSchedule;
          delete formData.activeFromDate;
        }
        if (!formData.wasteGeneration) {
          delete formData.wasteGenerationTerytPk;
          delete formData.wasteGenerationTerytCommune;
          delete formData.wasteGenerationAdditionalInfo;
        }
        if (props.serviceButtonMode !== ServiceButtonMode.EDIT) {
          if (formData.clientTransportPrice.price == '') {
            formData.clientTransportPrice.price = '0.0';
          }
          if (formData.subcontractorTransportPrice.price == '') {
            formData.subcontractorTransportPrice.price = '0.0';
          }
        }
        if (formData.resourceInBulkNoPackage) {
          delete formData.collectionInfrastructureServiceId;
          formData.collectionInfrastructureCount = 1;
          formData.collectionInfrastructureOutputMass = formData.noPackageMass;
        }
        if (props.type !== ServiceType.PER_REQUEST) {
          delete formData.reactionTime;
        }
        delete formData.noPackageMass;
        if (initialService?.requestEmails) {
          formData.requestEmails = initialService.requestEmails;
        }
        return formData;
      }}
      onSaved={async () => {
        if (props.onSaved) {
          props.onSaved();
        }
        if (props.serviceButtonMode !== ServiceButtonMode.EDIT) props.reloadServices();
      }}
      validationSchema={serviceValidationSchema(t, props.serviceButtonMode === ServiceButtonMode.EDIT, props.type)}
    />
  );
};
