import { Dialog, GeneralForm, GeneralFormItem, GeneralFormRowOfItems, LargeAddIcon, RenderIf } from '@lib/ui-components';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { Breakpoint } from '@mui/system';

export interface ButtonFormDialogProps {
  dialogTitle?: string;
  fields?: (GeneralFormItem | GeneralFormRowOfItems)[];
  validationSchema?: any;
  hiddenValues?: any;
  dialogIcon?: JSX.Element;
  maxWidth?: Breakpoint | false;
  bottomMessage?: string;
  openDialog: boolean;
  setDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleSaveDialog: (formData: any) => void;
  warningMessage?: string;
}

export const ButtonFormDialog = (props: ButtonFormDialogProps) => {
  async function handleCloseDialog() {
    props.setDialog(false);
  }

  return (
    <Dialog open={props.openDialog} onClose={() => handleCloseDialog()} maxWidth={props.maxWidth === undefined ? 'md' : props.maxWidth} fullWidth={true}>
      <div style={{ margin: 'auto', marginTop: '2rem' }}>{props.dialogIcon ? props.dialogIcon : <LargeAddIcon />}</div>
      <DialogTitle sx={{ margin: 'auto' }}>
        {props.dialogTitle}
        <IconButton
          aria-label='close'
          onClick={handleCloseDialog}
          data-test-id={'dialog-close-btn'}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <RenderIf true={props.warningMessage !== undefined && props.warningMessage !== ''}>
          <DialogTitle
            sx={{
              fontSize: '1rem',
              marginLeft: '-1rem',
              textAlign: 'left',
              color: 'error.main',
            }}
          >
            {props.warningMessage}
          </DialogTitle>
        </RenderIf>
        <GeneralForm
          fields={props.fields || []}
          validationSchema={props.validationSchema}
          onSaved={async (data) => props.handleSaveDialog(data)}
          onCanceled={async () => handleCloseDialog()}
          hideCancelButton={false}
          hiddenValues={props.hiddenValues}
          variant='standard'
        />
        {props.bottomMessage && (
          <DialogTitle
            sx={{
              margin: 'auto',
              fontSize: '1rem',
              maxWidth: '30rem',
              textAlign: 'center',
            }}
          >
            {props.bottomMessage}
          </DialogTitle>
        )}
      </DialogContent>
    </Dialog>
  );
};
